<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 text-center">
                <h4 class="text-success">Income</h4>
                <h2 class="text-success" v-if="account">${{ account.investors_account.investment_total_amount | to2DecimalPlace}}</h2>
                <h3 class="text-success" v-else>Loading...</h3>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6 border-right">
                <h5 class="text-muted">Total Profit</h5>
                <h4><i class="fa fa-dollar-sign"/>2000</h4>
              </div>
              <div class="col-6">
                <h5 class="text-muted">Total Investments</h5>
                <h4>20</h4>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end col -->
    </div>


    <div class="row">
      <div class="col-md-8 offset-md-2">
        <b-overlay :show="loader" rounded="sm">

          <template #overlay>
            <div>
              <b-spinner small type="grow" variant="danger"></b-spinner>
              <b-spinner type="grow" variant="danger"></b-spinner>
              <b-spinner small type="grow" variant="danger"></b-spinner>
              <span class="sr-only text-dark">Please wait...</span>
            </div>
          </template>

          <div class="card">
            <div class="card-body">
              <div class="float-right" @click="getAllTransactions"><i class="fa fa-sync"/></div>
              <h4 class="header-title mb-3">Income statistics</h4>
              <div class="table-responsive mb-0">
                <table class="table table-borderless table-hover table-centered m-0">
                  <thead class="thead-light">
                  <tr>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Account Number</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(d,i) in data" :key="i">
                    <td>
                            <span :class="{
                    'bg-soft-success text-success': `${d['trans_status']}` === 'pending',
                    'bg-soft-warning text-warning':
                      `${d['trans_status']}` === 'Upcoming',
                    'bg-soft-danger  text-danger':
                      `${d['trans_status']}` === 'Overdue'
                  }" class="badge">{{ d['trans_status'] }}</span>
                    </td>
                    <td>
                      <h5 class="m-0 font-weight-normal">{{ d['trans_amount'] }}</h5>
                    </td>
                    <td>{{ d['destination_account_number'] }}</td>


                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- end .table-responsive-->
            </div>
          </div>


        </b-overlay>

      </div>
    </div>
    <!-- end row -->
    <mobilefooter/>

  </div>
</template>

<script>


export default {
  name: "Transactions",
  components: {
    mobilefooter: () => import("@/components/MobileFooter")
  },
  data() {
    return {
      data: [],
      loader:false,
      accountLoader:false,
      account:''
    }
  },
  methods: {
    getAllTransactions() {
      this.loader = true;
      this.$store.dispatch('allTransactions').then((res) => {
        const {status, extra} = res;
        if (status) {
          this.data = extra.data;
        }
      }).finally(() => {
        this.loader = false;
      })
    },
    getUserAccountInfo() {
      this.accountLoader = true;
      this.$store.dispatch('getUserAccountDetails').then((res) => {
        const {status, extra} = res;
        if (status) {
          this.account = extra;
        }
      }).finally(() => {
        this.accountLoader = false;
      })
    }


  },
  created() {
    this.getAllTransactions();
    this.getUserAccountInfo();

  }


}
</script>

<style scoped>

</style>